import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import {device} from "../../constants/media";
import {withRouter} from "next/router";

const ImageSlide = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  width: 100vw !important;
  height: calc(100vh - 100px);
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom;
  box-sizing: border-box;
  position: relative;
`;

const AwardSlide = styled.img`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 60%;

  @media ${device.below.tablet} {
    width: 100%;
  }
`;

const Desc = styled.div`
  text-align: justify;
  margin-bottom: 40px;
  font-family: Verdana, Geneva, sans-serif;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  width: 90%;
  max-width: 910px;
  margin: 50px auto;
`;

const Slide = styled.div``;

class HomeSlider extends React.Component {
  get_image() {
    const pagepicture_set = this.props.page.pictures || [];
    const awards = this.props.awards.pictures || [];

    if (!pagepicture_set || pagepicture_set.length <= 0) {
      return null;
    }

    const settings = {
      dots: false,
      infinite: true,
      lazyLoad: true,
      fade: true,
      speed: 2000,
      autoplay: true,
      pauseOnHover: false,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <Slider {...settings}>
        {pagepicture_set.map((slide, index) => {
          return (
            <Slide key={slide.id}>
              <ImageSlide src={slide.picture} alt={slide.alt_text} />
              <AwardSlide
                key={awards[index].id}
                src={awards[index].picture}
                alt={awards[index].alt_text}
              />
            </Slide>
          );
        })}
      </Slider>
    );
  }

  get_content() {
    const content = this.props.page.text;

    if (!content || content === "") {
      return null;
    }

    return (
      <Content>
        <Desc dangerouslySetInnerHTML={{__html: content}} />
      </Content>
    );
  }

  render() {
    return (
      <>
        {this.get_image()}
        {this.get_content()}
      </>
    );
  }
}

export default withRouter(HomeSlider);
