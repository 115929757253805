import {api} from "../config";
import axios from "axios";

export const fetcher = (url, language, ...params) =>
  axios
    .get(`${api}${url}`, {
      headers: {"Accept-Language": language},
      params: params,
    })
    .then(res => res.data);

export default fetcher;
