import React from "react";
import styled from "styled-components";
import {NextSeo} from "next-seo";

import Page from "../components/Page";
import HomeSlider from "../components/HomeSlider";
import fetcher from "../utils/fetcher";
import useSWR from "swr";

const Container = styled.div`
  display: block;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 100px);
`;

function HomePage(props) {
  const initialDataPage = props.data?.page;
  const initialDataPageAwards = props.data?.page_awards;

  const {data} = useSWR("/pages/home/", fetcher, {initialData: initialDataPage});
  const {data: data_awards} = useSWR("/pages/home-awards/", fetcher, {
    initialData: initialDataPageAwards,
  });

  return (
    <Page>
      <NextSeo title="Home" description="Zav architect." />

      <Container>
        <HomeSlider page={data} awards={data_awards} />
      </Container>
    </Page>
  );
}

export async function getStaticProps(context) {
  const language = context.locale || "en";
  const page = await fetcher("/pages/home/", language);
  const page_awards = await fetcher("/pages/home-awards/", language);

  return {
    props: {
      data: {
        page,
        page_awards,
      },
    },
    revalidate: 60,
  };
}

export default HomePage;
